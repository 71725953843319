import React from "react"
import { graphql, PageProps } from "gatsby"
import Template from "@app/components/Article/Article"

export type Props = PageProps<GatsbyTypes.TemplateArticleQuery, GatsbyTypes.TemplateArticleQueryVariables>

export const query = graphql`
  query TemplateArticle($id: String!) {
    article: sanityArticle(_id: { eq: $id }) {
      ...SanityArticleFragment
    }
    relatedArticles: allSanityArticle {
      edges {
        next {
          handle {
            current
          }
          url
          title
        }
        node {
          handle {
            current
          }
          url
          title
        }
        previous {
          handle {
            current
          }
          url
          title
        }
      }
    }
    template: sanityTemplateArticle {
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
  fragment SanityArticleFragment on SanityArticle {
    title
    subtitle
    handle {
      current
    }
    url
    _createdAt
    metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    content: _rawContent(resolveReferences: { maxDepth: 6 })
    image: _rawImage(resolveReferences: { maxDepth: 2 })
    previewImage: _rawPreviewImage(resolveReferences: { maxDepth: 2 })
    categories: _rawCategories(resolveReferences: { maxDepth: 4 })
    attributes: _rawAttributes(resolveReferences: { maxDepth: 4 })
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Template {...props} {...data} />
export default Component
