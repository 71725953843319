import React from "react"
import { Container, Box, HStack, Tag, Heading, Button, Flex, Divider, Image, VStack } from "@chakra-ui/react"
import { BsDot } from "react-icons/bs"
import { format } from "date-fns"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"

import monogram from "../../../public/images/monogram.png"

import { useRoutes } from "@app/hooks/useRoutes"
import type { Props } from "@app/templates/article"
import type { PageProps } from "@root/types/global"
import ArticleNavigation from "./ArticleNavigation"
import { useImage } from "@app/hooks/useImage"
import { useMediaMobile } from "@app/hooks/useMedia"
import Sections from "../Sections/Sections"
import { useKlaviyo } from "@app/hooks/useKlaviyo"

const Article: React.FC<PageProps<Props>> = ({ article, relatedArticles }) => {
  const { urlResolver } = useRoutes()
  const { getResponsiveImage } = useImage()
  const isMobile = useMediaMobile()
  const { track } = useKlaviyo()
  const item = {
    ...article,
    author: article?.attributes?.author?.name || "",
    tags: article?.attributes?.tags || [],
    categories: article?.categories?.map(urlResolver) || [],
    image: getResponsiveImage(article?.image),
    date: article.attributes?.publishedAt ? format(new Date(article.attributes.publishedAt), "dd.MM.yy") : "",
  }

  const { next, previous } = relatedArticles?.edges?.find(featArticle => featArticle.node.handle.current === item.handle.current) || {}

  const nextArticle = urlResolver(next)
  const previousArticle = urlResolver(previous)

  const location = useLocation()
  const subject = "Check this article on Calibre"
  const handleShare = () => {
    track("Blog Share", { title: article?.title })
    window.open(`mailto:?subject=${subject}&body=${location.href}`, "_blank")
  }

  return (
    <Box pt={8} pb={12}>
      <Container maxW="container.lg">
        <Heading as="h2" textAlign="left" mb={5} size="display">
          Journal.
        </Heading>
        <ArticleNavigation />
      </Container>
      {item?.image?.desktop && !isMobile && <Image {...item?.image?.desktop} w="full" />}
      {item?.image?.mobile && isMobile && <Image {...item?.image?.mobile} w="full" />}
      <Container maxW="container.article" mt="10">
        <HStack align="center" spacing="4" divider={<BsDot />} ml="-1.5" mb={8}>
          {item?.tags?.length > 0 &&
            item?.tags.map(tag => (
              <Tag variant="unstyled" key={tag.toString()}>
                {tag}
              </Tag>
            ))}
        </HStack>
        <Flex direction={["column", "row"]} justify="space-between">
          <VStack align="start" mb={8}>
            <Heading as="h1" size="h1" mb={[2, 4]}>
              {item?.title}
            </Heading>
            <Heading as="p" size="h5">
              {item?.date}
            </Heading>
          </VStack>
          <Box>
            <Button size="sm" variant="outline" onClick={handleShare}>
              Share
            </Button>
          </Box>
        </Flex>
      </Container>
      <Sections page={{ content: article?.content }} />
      <Container maxW="container.lg">
        <Flex align="center" justify="center" pb={10}>
          <Image width={"60px"} src={monogram} />
        </Flex>
        <Divider />
        <HStack justify="center" spacing={4} maxW="xl" mx="auto">
          <Button
            disabled={!previousArticle.title}
            as={previousArticle.title ? Link : "button"}
            title={previousArticle.title}
            to={previousArticle.url}
            variant="outline"
            size="sm"
            width={["full", "160px"]}
          >
            PREVIOUS
          </Button>

          <Button
            disabled={!nextArticle.title}
            as={nextArticle.title ? Link : "button"}
            title={nextArticle.title}
            to={nextArticle.url}
            variant="outline"
            size="sm"
            width={["full", "160px"]}
          >
            NEXT ARTICLE
          </Button>
        </HStack>
      </Container>
    </Box>
  )
}

export default Article
